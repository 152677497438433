@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  flex: 0 0 auto;
  background: color('graylight');
  border-top: 1px solid color('black30');

  @include use-spacings(padding-top, 'm');
  @include use-spacings(padding-bottom, 'l');
  @include use-spacings(margin-top, 'xl');
}
