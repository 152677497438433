@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.row {
  @media (min-width: $tablet-size) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: $desktop-size) {
    @include columns();
  }
}

.col {
  @include use-spacings(margin-bottom, 'l');

  @media (min-width: $mobile-size) {
    margin-bottom: 0;

    @include columns();
  }
}

.logo-wrapper {
  @media (min-width: $tablet-size) {
    flex-grow: 1;
  }
}

.contact-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $mobile-size) {
    display: block;
  }

  @media (min-width: $tablet-size) {
    flex-grow: 1;
  }
}

.small-text {
  @include extra-small-text();

  @media (min-width: $tablet-size) {
    font-size: 12px;
  }
}

.entrance {
  @include small-text();
}

.entrance-link {
  a {
    position: relative;
    color: #000;
    cursor: pointer;

    &:hover {
      color: #00a1dc;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: -8px;
      display: block;
      width: 1px;
      height: 80%;
      content: '';
      background: #000;
      transform: translateY(-50%);
    }

    &:last-of-type {
      &::after {
        content: initial;
      }
    }
  }
}

.socials {
  width: min-content;

  @include use-spacings(margin, 'm', auto);

  a:not(:last-child) {
    @include use-spacings(margin-right, 'm');
  }

  @media (min-width: $mobile-size) {
    width: auto;
  }
}

.contacts {
  a {
    @include h5-text();
  }

  @media (min-width: $tablet-size) {
    display: block;
    width: 190px;
    margin-left: auto;
  }

  @media (min-width: $desktop-size) {
    width: initial;
    margin-left: initial;
  }
}

.logo {
  width: $mobile-menu-logo-width;
  min-height: 40px;
  max-height: 40px;
}

.nav {
  @include use-spacings(margin, 'xl', 0, 'l');

  @media (min-width: 540px) {
    columns: 2;
  }

  @media (min-width: $mobile-size) {
    @include use-spacings(margin-top, 'l');

    display: flex;
    justify-content: space-between;
    width: 98%;
  }

  @media (min-width: $desktop-size) {
    width: 90%;
  }
}

.third {
  @media (min-width: 540px) {
    width: 200px;
    margin-left: auto;
  }

  @media (min-width: $mobile-size) {
    width: initial;
    margin-left: initial;
  }

  @media (min-width: $desktop-size) {
    position: relative;
    left: -20px;
  }
}

.fourth {
  @media (min-width: 540px) {
    width: 200px;
    margin-left: auto;
  }

  @media (min-width: $mobile-size) {
    width: initial;
    margin-left: initial;
  }
}

.nav-list {
  @media (min-width: 540px) {
    margin-bottom: 10px;
  }

  @media (min-width: $mobile-size) {
    width: 157px;
    margin-bottom: initial;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $tablet-size) {
    width: 220px;
  }

  @media (min-width: $desktop-size) {
    width: 170px;
  }
}

.nav-item,
.nav-sub-list {
  display: block;

  @include use-spacings(margin-bottom, 's');

  &:last-child {
    margin-bottom: 0;
  }
}

.info {
  position: relative;
  width: 100%;
}

// logos

.logos {
  display: flex;
  flex-direction: column;

  @include use-spacings(margin, l, auto);
}

.logos-item {
  display: flex;
  align-items: center;

  span {
    width: 150px;

    @include small-light-text();
  }

  &:not(:last-child) {
    @include use-spacings(margin-bottom, l);
  }
}

.logos-sbrf-wow {
  @include use-spacings(margin-bottom, s);
}

.logos-logo {
  @include use-spacings(margin-right, s);
}

.logos-sbrf {
  width: 162px;
  height: auto;
}

.logos-skolkovo {
  width: 78px;
  height: auto;
}

.cookies-info {
  @include use-spacings(margin-bottom, l);

  .logos {
    display: none;
  }
}

.privacy {
  // for media
}

.light-text {
  letter-spacing: 0.03em;
  opacity: 0.5;

  @include extra-small-text();
}

.padding {
  display: inline-block;

  @include use-spacings(padding-top, 'xs');
}

.legal-information-title {
  @include use-spacings(margin-bottom, 'xs');
  @include small-text();

  @media (min-width: $mobile-size) {
    @include use-spacings(margin-top, 'l');
  }
}

.current-city {
  color: color(main);

  @include use-spacings(margin-left, m);
  @include extra-small-text();

  &:hover {
    cursor: pointer;
  }
}

@media (min-width: $extra-mobile-size) {
  .logos {
    flex-direction: row;
    width: 100%;
  }

  .logos-item {
    &:not(:last-child) {
      margin-bottom: 0;

      @include use-spacings(margin-right, l);
    }
  }
}

@media (min-width: $mobile-size) {
  .logo-wrap {
    display: block;
    min-height: 52px;
  }

  .list {
    @include columns(1);

    ul {
      // @include columns(2);

      margin-top: -43px;

      // > li:first-child {
      //   height: 100px;
      //   margin-top: 43px;
      // }
    }
  }
}

@media (min-width: $tablet-size) {
  .spoiler-button {
    margin-top: 0;
  }

  .cookies-info {
    @include flex-columns();

    .logos {
      display: flex;
    }
  }

  .privacy {
    @include flex-columns();

    .logos {
      display: none;
    }
  }

  .logos {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (min-width: $desktop-size) {
  .logo-wrap {
    min-height: auto;
  }
}

.link {
  display: block;
  margin-top: 20px;
  color: color('main');
  cursor: pointer;

  @include small-text();
}

.wowTop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 768px) {
    justify-content: initial;
  }

  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

.wowSocials {
  @media (min-width: 768px) {
    margin-left: 33%;
  }

  @media (min-width: 1024px) {
    margin-left: 12%;
  }

  @media (min-width: 1444px) {
    margin-left: 10%;
  }
}

.wowContacts {
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    width: initial;
    margin-top: initial;
    margin-bottom: initial;
    margin-left: 8%;
  }

  @media (min-width: 1444px) {
    margin-left: 12%;
  }
}

.wowContent {
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1444px) {
    display: flex;
    justify-content: space-between;
  }
}

.wowBlock {
  @media (min-width: 1444px) {
    max-width: 563px;
  }
}

.wowLogos {
  @media (min-width: 540px) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    max-width: 480px;
  }
}

.wowCookiesInfo {
  margin-top: 0;
  margin-bottom: 24px;
}

.wowCopyright {
  display: block;
  margin-bottom: 16px;

  @media (min-width: 1024px) {
    max-width: 400px;
    margin-right: 24px;
  }
}

.wowRegions {
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    max-width: 322px;
  }
}

.mobileRegions {
  @media (min-width: 1024px) {
    display: none;
  }
}

.desktopRegions {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.wowWrapper {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
}

.top {
  @include flex();

  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.rightPart {
  @include flex();
}

.nlpSocials {
  @include use-spacings(margin-right, 32px);
}

.light {
  letter-spacing: 0.03em;
  opacity: 0.5;

  @include use-spacings(margin-bottom, s);
  @include extra-small-text();
}

.logosWrap {
  @include flex();
  @include use-spacings(margin, l, auto);

  flex-wrap: wrap;
  align-items: center;

  @media (min-width: $tablet-size) {
    @include use-spacings(margin-top, 0);
  }
}

.sbr {
  @include flex();

  align-items: center;

  @include use-spacings(margin-bottom, s);
}

.logoSbr {
  @include use-spacings(margin-right, l);
}

.appWrap {
  @include use-spacings(margin, l, auto);
}

.appHeader {
  @include use-spacings(margin-bottom, xs);
}

.appTitle {
  font-size: 20px;
  line-height: 28px;

  @include use-spacings(margin-bottom, 0);
}

.appDesc {
  @include use-spacings(margin-bottom, 0);
}

.nlpWrapper {
  max-width: 540px;

  @include use-spacings(margin-bottom, l);
}

.nlpRegions {
  @media (min-width: $mobile-size) {
    @include flex();

    justify-content: space-between;
    max-width: 322px;
  }
}

.nlpRegionLink {
  @include use-spacings(margin-bottom, 0);
}

.blockWrap {
  @media (min-width: $tablet-size) {
    @include flex();
    @include use-spacings(margin-top, xl);

    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.rightBlock {
  @media (min-width: $tablet-size) {
    @include use-spacings(margin-left, m);
  }
}

.leftBlock {
  @media (min-width: $tablet-size) {
    max-width: 480px;
  }
}

.singleCookie {
  @media (min-width: $tablet-size) {
    display: none;
  }
}

.partCookie {
  display: none;

  @media (min-width: $tablet-size) {
    display: block;
  }
}

.nlpContacts {
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    width: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
