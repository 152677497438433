.wrapper {
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.content {
  position: relative;
  z-index: 6;
}
