@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.text {
  @include use-spacings(margin-top, m);
}

.light {
  letter-spacing: 0.03em;
  opacity: 0.5;

  @include use-spacings(margin-bottom, s);
  @include extra-small-text();
}
